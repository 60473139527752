import {createApp} from 'vue';

import '@/css/app.scss';
import CmbvlMap from "@/vue/CmbvlMap.vue";
import ResponsiveMenu from "@/vue/ResponsiveMenu.vue";
import Accordion from "@/vue/Accordion.vue";
import CsrfInput from "@/vue/CsrfInput.vue";
import FlashMessage from "@/vue/FlashMessage.vue";
import Carrousel from "@/vue/Carrousel.vue";

// App main
const main = async () => {
  // Create our vue instance
  const app = createApp({
    name: 'App',
    components: {
      CmbvlMap,
      ResponsiveMenu,
      Accordion,
      FlashMessage,
      CsrfInput,
      Carrousel,
    },
  });

  // Mount the app
  return app.mount('#vue-root');
};

// Execute async function
main().then(() => {
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept();
}
