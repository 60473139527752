<script setup>
import {ref} from 'vue';
import ResponsiveMenuItem from '@/vue/ResponsiveMenuItem.vue';
import MenuButton from '@/vue/MenuButton.vue';

const openedItemId = ref(null);
const isMenuOpened = ref(false);

const props = defineProps({
  ariaLabel: {type: String, default: null},
  nav: {type: Array, required: true},
});

function toggleItem(itemId, isOpen) {
  openedItemId.value = isOpen ? itemId : null;
}
</script>

<template>
  <nav class="responsive-menu inset-0 z-30 flex flex-col bg-white" :class="{ 'responsive-menu--is-open': isMenuOpened }">
    <div class="responsive-menu__top-bar flex justify-between items-center h-20 p-4 bg-white">
      <slot></slot>
      <menu-button class=" responsive-menu__button" type="collapse" v-model="isMenuOpened" />
    </div>


    <div class="responsive-menu__menu-container">
      <ol class="responsive-menu__menu">
        <responsive-menu-item v-for="(item, i) in nav"
                              :key="i"
                              :item="item"
                              :is-open="openedItemId === i"
                              class="responsive-menu__item"
                              @toggle="(isOpen) => toggleItem(i, isOpen)"
        />
      </ol>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.responsive-menu {
  position: sticky;

  &__menu-container {
    height: 0;
    overflow: hidden;
    transition: height 200ms ease-out;
  }

  &__menu {
    @apply px-8;
    height: 100%;
    overflow-y: auto;
    overflow-x: visible;
  }

  &--is-open &__menu-container {
    height: calc(100vh - 80px);
  }

  &__item {
    @apply border-b last:border-0 border-primary;
  }

  @screen lg {
    & {
      @apply flex-row relative;
    }

    &__menu-container {
      @apply h-auto overflow-visible;
    }

    &__menu {
      @apply flex items-center overflow-visible;
    }

    &__button {
      @apply hidden;
    }

    &__item {
      @apply border-0;
    }
  }
}
</style>
