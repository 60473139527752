<script setup>
import { MapboxMap, MapboxMarker } from '@studiometa/vue-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { defineProps, onMounted, ref } from 'vue';
import mapboxgl from 'mapbox-gl';

const props = defineProps({
  markers: Array,
  accessToken: String,
});

const map = ref();
onMounted(() => {
  // Create a 'LngLatBounds' with the first coordinate.
  const bounds = new mapboxgl.LngLatBounds([
      props.markers[0],
      props.markers[0],
  ]);

// Extend the 'LngLatBounds' to include every coordinate in the bounds result.
  for (const lngLat of props.markers.map(marker => [marker.lng, marker.lat])) {
    bounds.extend(lngLat);
  }

  map.value.map.fitBounds(bounds, {
    padding: [50, 100],
    animate: false,
    maxZoom: window.innerWidth < 768 ? 11 : 12,
  });
});
</script>

<template>
  <MapboxMap class="h-[75vh]"
             :access-token="props.accessToken"
             map-style="mapbox://styles/mapbox/outdoors-v9"
             :cooperative-gestures="true"
             ref="map"
  >
    <MapboxMarker v-for="marker in props.markers"
                  :key="marker.id"
                  :lng-lat="[marker.lng, marker.lat]"
                  :color="marker.color"
    >
      <template #popup>
        {{ marker.title }}
      </template>
    </MapboxMarker>
  </MapboxMap>
</template>

<style scoped lang="scss">

</style>
