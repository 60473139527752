<template>
    <section
        class="py-10"
        :class="{
          [`text-${textColor}`]: textColor,
          [`bg-${backgroundColor}-100`]: backgroundColor !== 'white',
        }"
    >

      <div class="container">
        <dl class="accordion__container">
          <div v-for="(panel) in panels"
               :key="panel.id"
               class="accordion-item"
               :class="{ 'accordion-item--is-open': openPanels.includes(panel.id)}"
          >
            <dt class="accordion-item__heading h4 py-4 cursor-pointer flex justify-between items-center"
                @click="togglePanel(panel.id)"
            >
              <button type="button"
                      class="text-left"
                      :aria-expanded="openPanels.includes(panel.id)"
                      :aria-controls="`${uuid}-accordion-panel-${panel.id}`"
                      :id="`${uuid}-accordion-${panel.id}`"
                      v-text="panel.title"
              />
            </dt>
            <dd class="overflow-hidden"
                :aria-labelledby="`${uuid}-accordion-${panel.id}`"
                :id="`${uuid}-accordion-panel-${panel.id}`"
            >
              <div class="prose max-w-none pb-10" v-html="panel.text"></div>
              <a v-if="panel.cta"
                 :href="panel.cta.href"
                 :target="panel.cta.target"
                 class="cta cta--primary inline-block mb-6" v-text="panel.cta.text"
              />
            </dd>
          </div>
        </dl>
      </div>
    </section>
</template>

<script setup>
import {onMounted, ref} from "vue";
  const uuid = crypto.randomUUID();

  const props = defineProps({
    panels: {type: Array, default: () => []},
    firstPanelOpen: {type: Boolean, default: false},
    singleOpenPanel: {type: Boolean, default: true},
    backgroundColor: {type: String, default: 'white'},
    textColor: {type: String, default: 'black'}
  });

  const openPanels = ref([]);

  onMounted(() => {
    if (props.firstPanelOpen) {
      if (props.panels.length === 0) {
        return
      }

      togglePanel(props.panels[0].id);
    }
  });

  function togglePanel(index) {
    const position = openPanels.value.indexOf(index)
    if (position > -1) {
      openPanels.value.splice(position, 1);
      return
    }

    if (props.singleOpenPanel) {
      openPanels.value = [];
    }

    openPanels.value.push(index);
  }
</script>

<style lang="scss">
.accordion {
  &__container {
    @apply border-b-2 border-primary
  }

  &__indicator {}

  &__panel {}
}

.accordion-item {
  @apply grid;
  grid-template-rows: min-content 0fr;
  transition: grid-template-rows 300ms ease-out;

  &--is-open {
    grid-template-rows: min-content 1fr;
  }

  &__heading {
    @apply flex justify-between items-center border-0 border-t-2 border-t-primary text-primary;
  }
  &__heading::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 3v8h8v2h-8v8h-2v-8H3v-2h8V3h2z' fill='%23000' fill-rule='evenodd'/%3E%3C/svg%3E");
    @apply  overflow-hidden h-6 w-6;
    transition: transform 200ms ease-out;
  }

  &--is-open &__heading::after {
    transform: rotate(135deg);
  }
}
</style>
