<script setup>
const props = defineProps({
  item: {type: Object, required: true},
  isOpen: {type: Boolean, default: false},
});

const emit = defineEmits(['toggle']);

function toggle() {
  emit('toggle', !props.isOpen);
}
</script>

<template>
  <li class="menu-item" :class="{ 'menu-item--has-children': item.children, 'menu-item--is-open': isOpen }">
    <span v-if="item.children" v-text="item.text" class="menu-item__text" @click="toggle"/>
    <a v-else :href="item.href" :target="item.target" class="menu-item__text" v-text="item.text"/>

    <div v-if="item.children" class="menu-item__children-wrapper shadow-xl">
        <div class="menu-item__children">
          <div class="p-2">
            <responsive-menu-item v-for="(subItem, i) in item.children" :key="i" :item="subItem"/>
          </div>
        </div>
    </div>
  </li>
</template>

<style lang="scss">
.menu-item {
  @apply flex flex-col font-baloo2 font-bold text-primary text-xl;

  &--has-children > &__text::after {
    content: '>';
    transition: transform 150ms ease-in-out;
  }

  &--is-open.menu-item--has-children > &__text::after {
    transform: rotate(90deg);
  }

  &__children-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 250ms ease-out;
  }

  &--is-open &__children-wrapper {
    grid-template-rows: 1fr;
  }

  &__children {
    @apply overflow-hidden pl-4;
  }

  &__text {
    @apply inline-flex justify-between py-4 select-none cursor-pointer;
  }

  &__children &__text {
    @apply font-normal text-black;
  }

  @screen lg {
    & {
      @apply h-full justify-center;
    }

    &__text {
      @apply px-6 text-current;
    }

    &--has-children > &__text::after {
      content: none;
    }

    &__children-wrapper {
      @apply grid justify-center absolute inset-x-0 top-full bg-primary opacity-0;
      transition-property: all;
    }

    &--has-children:hover > &__children-wrapper,
    &--has-children:focus-within > &__children-wrapper,
    &--force-dropdown > &__children-wrapper {
      @apply opacity-100;
      grid-template-rows: 1fr;
    }

    &__children {
      @apply grid px-0;
      grid-template-columns: repeat(2, minmax(0, max-content));
    }

    &__children &__text {
      @apply flex w-fit text-white py-3 text-lg;
    }
  }
}
</style>
