<script setup>
import { ref } from "vue";
import useFixedKeenSlider from "@/vue/composables/useFixedKeenSlider";

const current = ref(1);
const [container, slider ] = useFixedKeenSlider({
  mode: 'free-snap',
  slides: {
    perView: 'auto',
    origin: 'center',
  },
  slideChanged(s) {
    current.value = s.track.details.rel;
  },
});
</script>

<template>
  <div class="relative">
    <div ref="container" class="keen-slider" style="max-width: 100%">
      <slot />
    </div>
    <svg
        @click="slider.prev()"
        :class="{
          arrow: true,
          'arrow--left': true,
          'arrow--disabled': current === 0,
        }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
      <path
          d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
      ></path>
    </svg>
    <svg
        v-if="slider"
        @click="slider.next()"
        :class="{
          arrow: true,
          'arrow--right': true,
          'arrow--disabled': current === slider.track.details.slides.length - 1,
        }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path>
    </svg>
  </div>
</template>

<style>
@import url('keen-slider/keen-slider.css');
</style>

<style lang="scss" scoped>
.arrow {
  width: 48px;
  height: 48px;
  background: var(--color-primary-500);
  padding: 9px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;

  &--left {
    left: 5px;
  }
  &--right {
    left: auto;
    right: 5px;
  }
  &--disabled {
    opacity: 0.25;
  }
}
</style>
