import { onMounted, onUnmounted, nextTick } from "vue";
import {useKeenSlider} from "keen-slider/vue.es.js";
import { useThrottleFn, useIntersectionObserver } from '@vueuse/core';

/**
 * Keen slider doesn't play nice with images whose dimensions aren't know before load.
 * This composable is a drop-in replacement of useKeenSlider() that aims at fixing that.
 */
export default function useKeenSliderFixed(options) {
    const [container, slider] = useKeenSlider(options);

    const updateSliderOnNextTick = () => nextTick(() => {
        slider.value.update();
    });
    const onImageLoaded = useThrottleFn(() => {
        updateSliderOnNextTick();
    }, 1000);

    const { stop: stopIntersectionObserver } = useIntersectionObserver(container, ([{ isIntersecting }], observerElement) => {
        if (!isIntersecting) {
            return;
        }

        stopIntersectionObserver();
        updateSliderOnNextTick();
    });

    onMounted(() => container.value.addEventListener('load', onImageLoaded, true));
    onUnmounted(() => container.value.removeEventListener('load', onImageLoaded, true));

    return [container, slider];
}
